import React, { useState } from "react";
import SiteCode from "../Reports/SiteCode";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import RegisteredDeviceTable from "./RegisteredDeviceTable";
import TelemetryLogs from "./TelemetryLogs";
import RegisteredBoxTable from "./RegisteredBoxTable";
import ButtonsTable from "./ButtonsTable";
import FirmwareTable from "./FirmwareTable";
import LogsTable from "./LogsTable";
import WsmsLogs from "./WsmsLogs";

const urlhttp = process.env.REACT_APP_URL;

const url = process.env.REACT_APP_URL;

function Admin() {
  const [site, setSite] = useState();
  const [imei, setImei] = useState();
  const [sim, setSim] = useState();
  const [deviceType, setDeviceType] = useState();
  const [deviceModel, setDeviceModel] = useState();
  const [remarks, setRemarks] = useState();
  const registerDevice = () => {
    const URL = `${urlhttp}/core_data/device/registration`;

    const data = {
      site_code: site,
      device_type: deviceType,
      imei: imei,
      sim_no: sim,
      device_model_no: deviceModel,
      remarks: remarks,
    };
    axios.post(URL, data).then((response) => {
      console.log(response.data.message);
      console.log(response.status);

      alert(response.data.message);
    });
  };
  return (
    <>
      <div className="container-md mt-4">
        {/* <div className="header text-center">
          <div class="text-center mb-3 three">
            <h1 className="d-inline-block">Device Registration</h1>
          </div>
        </div> */}
        <div className="form mt-4">
          {/* <div className="row">
            <div className="col-lg-2">
              <TextField
                required
                id="outlined-required"
                label="Site Code"
                defaultValue="A-01-P"
                onChange={(e) => setSite(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <TextField
                required
                id="outlined-required"
                label="IMEI Number"
                defaultValue=""
                onChange={(e) => setImei(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <TextField
                required
                id="outlined-required"
                label="Sim Number"
                defaultValue=""
                onChange={(e) => setSim(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <TextField
                required
                id="outlined-required"
                label="Device Type"
                defaultValue=""
                onChange={(e) => setDeviceType(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <TextField
                required
                id="outlined-required"
                label="Device model"
                defaultValue=""
                onChange={(e) => setDeviceModel(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <TextField
                required
                id="outlined-required"
                label="Remarks"
                defaultValue=""
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
          </div>
          {/* <div className="bu mt-4 text-center">
            <Button onClick={registerDevice} variant="outlined">
              Register
            </Button>
          </div> */}
          <div className="header">
            <div class="text-center mb-3 mt-4 three">
              <h1 className="d-inline-block">Device Management</h1>
            </div>
          </div>
          <RegisteredDeviceTable />
          <h1 className="d-inline-block">Box Management</h1>
          {/* <WsmsLogs/> */}
          <RegisteredBoxTable />
          <TelemetryLogs />
          <ButtonsTable />
          <FirmwareTable />
          {/* <LogsTable /> */}
        </div>
      </div>
    </>
  );
}

export default Admin;
